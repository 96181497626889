// Here you can add other styles

.btn-primary {
  background: #6122cc !important;
  border: 1px solid #6122cc !important;
}

.c-primary {
  color: #6122cc !important;
}

.c-success {
  color: #2eb85c !important;
}

.button-icon {
  border: 0;
  outline: none;
  background: transparent;
  display: inline;
}

.loading {
  background: rgba(0,0,0, 0.3);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-z-index {
  z-index: 1101;
}

.pointer-link {
  @extend .c-primary;
  cursor: pointer;
  text-decoration: underline;
}

.header-valanti {
  background: #f2f2f2;
  font-weight: 700;
  @extend .c-primary;
}

.open-modal {
  @extend .c-primary;
  cursor: pointer;
  text-decoration: underline;
}

.box-test {
  @extend .c-primary;
  border: 1px solid rgba(#ccc, .5);
  border-radius: 5px;
  padding: 1rem 1.5rem;
  font-size: 15pt;
  cursor: pointer;
  text-align: center;
}

.title-profile {
  @extend .c-primary;
  font-size: 16pt;
  font-weight: 500;
}

.border-radius-circle {
  border-radius: 50em;
}

.sub-hv {
  color: #19930B;
  border-bottom: 2px solid rgba(87,87,86,0.3);
  font-weight: 500;
  font-size: 14pt;
  margin: 2rem 0;
  padding: 0.5rem 0;
  text-align: center;
}

.table td, .table th {
  padding: .20rem;
}

body {
  font-size: .72rem;
}

.fix-calendar .table-responsive {
  overflow-x: inherit !important;
}

@media print {
  @page { margin: 3rem; }
}

.c-sidebar .c-sidebar-brand {
  background-color: #fff !important;
}
